import { ODataFilter2 } from '@common/api/qs';
import { FilterFieldType, FilterFieldValue } from '@common/models';

import { RequestsFilter } from './requests-filter';

export class ODataIts2Requests extends ODataFilter2<RequestsFilter>
{
    constructor (incrSize: number, nbItems?: number)
    {
        super(incrSize, nbItems, 'eq');

        this._field4Filter = new Map<string, FilterFieldType>()
            .set('recipientIsMe', { name: 'recipientIsMe', type: 'switch' })
            .set('urgencyId', { name: 'urgencyId', type: 'int' })
            .set('type', { name: 'type', type: 'string' })
            .set('statusId', { name: 'statusId', type: 'int' })
            .set('metaStatusGuid', { name: 'metaStatusGuid', type: 'string', operator: 'eq' })
            .set('since', { name: 'submitDateUtc', type: 'date', operator: 'gt' })
            .set('until', { name: 'submitDateUtc', type: 'date', operator: 'lt' });
        this.exportValues = new Map<string, any>();

        this.setKeyValue('$count', ODataIts2Requests.count);
        this.addToFilter(new FilterFieldValue('recipientIsMe', true));
        this.processQueryString();
    }

    public addToFilter (ffv: FilterFieldValue)
    {
        if (this._field4Filter.has(ffv.name))
        {
            this.filter[ffv.name] = ffv.value;
        }
    }

    public changeOperator (field: string, operator: string)
    {
        if (this._field4Filter.has(field))
        {
            this._field4Filter.get(field).operator = operator;
        }
    }

    public removeFromFilter (ffv: FilterFieldValue)
    {
        if (this._field4Filter.has(ffv.name))
        {
            this.filter[ffv.name] = undefined;
        }
    }

    public processDefaultQueryString ()
    {
        this.setKeyValue('$filter', 'not(statusId in (8,2))');

        this.qs = this.getQueryString();
        this.qsExport = this.getExportQueryString();
    }
}

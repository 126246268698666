import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { take, tap } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { Its2Constants } from '@apps/its2/its2.constants';
import { IAuroraState } from '@apps/aurora.state';
import { FilterFieldValue } from '@common/models';
import * as fromIts2RequestsActions from '@apps/its2/store/requests/requests.actions';
import * as fromRootStore from '@apps/root/store';
import { ODataIts2Requests } from '@apps/its2/services/odata-its2-requests';
import { RequestsFilter } from '@apps/its2/services/requests-filter';
import { ResultDomain } from '@common/domain/result-domain';
import { Request } from '@apps/its2/domain/models';
import { getDefaultFilterActive } from '../store/its2.reducer';

export type UpdatableFieldsRequestsFilter = Omit<RequestsFilter, 'since' | 'until' | 'initFilter'>;

export class UpdateRequestsFilter
{
    constructor (public field: keyof UpdatableFieldsRequestsFilter, public value: any)
    {
    }
}

/**
 * Cette classe gère l'état d'avancement dans la liste (pagination)
 * Elle réagit aux changements de filtre ainsi qu'aux scrolls de la liste
 * Elle dispatch les événements vers la couche Effects
 */
@Injectable({
    providedIn: 'root'
})
export class RequestsFilterService
{
    private odataIts2Requests: ODataIts2Requests;
    private _requestsFilter: RequestsFilter;
    get requestsFilter ()
    {
        return this._requestsFilter;
    }
    get qsExport ()
    {
        return this.odataIts2Requests.qsExport;
    }

    constructor (
        private actions: Actions,
        private store: Store<IAuroraState>
    )
    {
        this.odataIts2Requests = new ODataIts2Requests(Its2Constants.QSRequestsTop);
        this.resetFilter();
        this.actions
            .pipe(
                ofType(fromIts2RequestsActions.Its2RequestsODataFilterSucceeded),
                tap((params: { result: ResultDomain<Request>; }): void =>
                {
                    this.odataIts2Requests.processQueryString();
                    this.odataIts2Requests.nbItems = params.result.count;
                })
            )
            .subscribe();
    }

    public changeMyTicketsFilter (myTickets: boolean): void
    {
        const xyz = new UpdateRequestsFilter('recipientIsMe', myTickets ?? null);

        this.updateRequestsFilter([xyz]);
    }

    // public changeFilterAndStatus (requestsFilter: RequestsFilter): void
    // {
    //     this.requestsFilter = requestsFilter;
    //     this.changeFilter(requestsFilter);
    // }

    public updateRequestsFilter (updates: UpdateRequestsFilter[]): void
    {
        updates.forEach((update: UpdateRequestsFilter): void =>
        {
            switch (update.field)
            {
                case 'recipientIsMe':
                    {
                        if (typeof update.value === 'boolean')
                        {
                            if (update.value)
                            {
                                if (!this._requestsFilter.recipientIsMe && this._requestsFilter.onlySecuRequests)
                                {
                                    this._requestsFilter.recipientIsMe = null;
                                    this._requestsFilter.onlySecuRequests = true;
                                }
                                else
                                {
                                    this._requestsFilter.recipientIsMe = true;
                                    this._requestsFilter.onlySecuRequests = false;
                                }
                            }
                            else
                            {
                                this._requestsFilter.recipientIsMe = null;
                                this._requestsFilter.onlySecuRequests = false;
                            }
                        }
                    }
                    break;

                case 'onlySecuRequests':
                    if (typeof update.value === 'boolean')
                    {
                        this._requestsFilter.onlySecuRequests = update.value;
                    }

                    break;

                case 'rangeSince':
                    this._requestsFilter.rangeSince = DateTime.isDateTime(update.value) ? update.value : null;

                    break;

                case 'rangeUntil':
                    this._requestsFilter.rangeUntil = DateTime.isDateTime(update.value) ? update.value : null;

                    break;

                case 'metaStatusGuid':
                    this._requestsFilter['catalogGuid'] = update.value;

                    break;

                default:
                    this._requestsFilter[update.field] = update.value;
            }
        });
        this.store.dispatch(fromIts2RequestsActions.Its2RequestsFilterChanged({
            requestsFilter: this._requestsFilter
        }));
        this.store.dispatch(fromRootStore.RootMiscStoreActions.RootFilterStatusSet({
            active: true
        }));
        this.applyFilter();
    }

    public changeOperator (field: string, operator: string)
    {
        this.odataIts2Requests.changeOperator(field, operator);
    }

    public resetFilter ()
    {
        this._requestsFilter = new RequestsFilter();
        this.store.dispatch(fromIts2RequestsActions.Its2RequestsFilterChanged({
            requestsFilter: this._requestsFilter
        }));
        this.store.dispatch(fromRootStore.RootMiscStoreActions.RootFilterStatusSet({
            active: false
        }));

        this.applyFilter();
    }

    public nextPage (): void
    {
        if (!this.odataIts2Requests.isEndReached)
        {
            this.odataIts2Requests.incr();
            this.processSkip();
        }
    }

    public reload ()
    {
        this.store.dispatch(fromIts2RequestsActions.Its2RequestsODataFilterRequested({ queryString: this.odataIts2Requests.qs }));
    }

    private applyFilter (): void
    {
        this.odataIts2Requests.resetIncr();
        Array.from(this.odataIts2Requests.field4Filter.keys())
            .map((key: string) =>
                this.odataIts2Requests.addToFilter(new FilterFieldValue(key, this._requestsFilter[key]))
            );
        this.processFilter();
    }

    // private pushValue (filterFieldValue: FilterFieldValue): void
    // {
    //     this.odataIts2Requests.addToFilter(filterFieldValue);
    //     this.process();
    // }

    // private popValue (filterFieldValue: FilterFieldValue): void
    // {
    //     this.odataIts2Requests.removeFromFilter(filterFieldValue);
    //     this.process();
    // }

    // private pushValues (filterFieldValues: FilterFieldValue[]): void
    // {
    //     filterFieldValues.forEach((filterFieldValue: FilterFieldValue) =>
    //         this.odataIts2Requests.addToFilter(filterFieldValue)
    //     );
    //     this.processFilter();
    // }

    private processFilter (): void
    {
        this.store.select(getDefaultFilterActive)
            .pipe(
                tap(defaultFilterActive =>
                {
                    if (defaultFilterActive)
                    {
                        this.odataIts2Requests.processDefaultQueryString();
                    }
                    else
                    {
                        this.odataIts2Requests.processQueryString();
                    }
                }),
                take(1)
            )
            .subscribe();

        this.store.dispatch(fromIts2RequestsActions.Its2RequestsODataFilterRequested({ queryString: this.odataIts2Requests.qs }));
    }

    private processSkip (): void
    {
        this.odataIts2Requests.processQueryString();
        this.store.dispatch(fromIts2RequestsActions.Its2RequestsODataSkipRequested({ queryString: this.odataIts2Requests.qs }));
    }
}

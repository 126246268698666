import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as fromAuthLoginActions from '@apps/auth/store/login/login.actions';
import * as fromAuthProfileActions from '@apps/auth/store/profile/profile.actions';
import * as fromRootStore from '@apps/root/store';
import { VeeamJob } from '@apps/bckp/domain/models/veeamJob';
import { VeeamJobsFilter } from '@apps/bckp/models/veeam-jobs-filter';
import { BckpConstants } from '@apps/bckp/bckp.constants';
import { ODataBckpVbrJobs } from './odata-bckp-vbr-jobs';
import { BckpVbrJobsCleared, BckpVbrJobsFilterApplied, BckpVbrJobsODataFailed, BckpVbrJobsODataIncremented, BckpVbrJobsODataInitialized, BckpVbrJobsODataLoaded, BckpVbrJobsQsWordSet } from './vbr-jobs.actions';

export const BckpVbrJobsFeatureKey = 'vbrJobs';

export type IVbrJobsState = EntityState<VeeamJob>;

export const vbrJobsAdapter: EntityAdapter<VeeamJob> = createEntityAdapter<VeeamJob>({
    selectId: (job: VeeamJob) => job.documentId
});

export const jobsInitialState: IVbrJobsState = vbrJobsAdapter.getInitialState({
});

export interface IBckpVbrJobsState
{
    collection: IVbrJobsState;
    oData: ODataBckpVbrJobs;
    qsWord: string;
    isLoading: boolean;
    isLoaded: boolean;
    isEmpty: boolean;
    isOups: boolean;
}

const initialState: IBckpVbrJobsState = {
    collection: jobsInitialState,
    oData: new ODataBckpVbrJobs(BckpConstants.QSJobsTop, new VeeamJobsFilter()),
    qsWord: '',
    isLoading: true,
    isLoaded: false,
    isEmpty: false,
    isOups: false
};

export const BckpVbrJobsReducer = createReducer(
    initialState,
    on(
        fromRootStore.RootImpeStoreActions.RootImpersonateSet,
        fromRootStore.RootImpeStoreActions.RootImpersonateUnset,
        fromAuthProfileActions.AuthProfileApplicationRolesSet,
        fromAuthProfileActions.AuthProfileApplicationRolesReset,
        fromAuthLoginActions.AuthLogout,
        (): IBckpVbrJobsState => initialState
    ),
    on(
        BckpVbrJobsODataInitialized,
        (state, { scrollSize, filter }) => ({
            ...state,
            oData: new ODataBckpVbrJobs(scrollSize, filter)
        })
    ),
    on(
        BckpVbrJobsODataLoaded,
        (state, { dataLoaded }) =>
        {
            const o = state.oData;

            o.nbItems = dataLoaded.count;

            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                isEmpty: o.nbItems === 0,
                oData: o,
                collection: vbrJobsAdapter.addMany(dataLoaded.value, state.collection)
            };
        }
    ),
    on(
        BckpVbrJobsODataFailed,
        (state) => ({
            ...state,
            isLoading: false,
            isLoaded: false,
            isOups: true,
            collection: jobsInitialState
        })
    ),
    on(
        BckpVbrJobsODataIncremented,
        (state) =>
        {
            const o = state.oData;

            o.incr();

            return {
                ...state,
                oData: o
            };
        }
    ),
    on(
        BckpVbrJobsFilterApplied,
        (_, { filter }) => ({
            ...initialState,
            oData: new ODataBckpVbrJobs(BckpConstants.QSJobsTop, filter)
        })
    ),
    on(
        BckpVbrJobsCleared,
        (state) => ({
            ...state,
            collection: vbrJobsAdapter.removeAll(state.collection)
        })
    ),
    on(
        BckpVbrJobsQsWordSet,
        (state, { qsWord }) => ({
            ...state,
            qsWord: qsWord.toLowerCase()
        })
    )
);

import { Injector } from '@angular/core';
import { VeeamJobsFilter } from '@apps/bckp/models/veeam-jobs-filter';

import { OData } from '@common/api/qs/odata';
import { DatesService } from '@common/services';

export class ODataBckpVbrJobs extends OData
{
    private static selectedFields = [
        'vmDisplayName',
        'jobType',
        'taskState',
        'taskCreationTime',
        'name',
        'sessionState',
        'nextRun',
        'sessionCreationTime',
        'taskEndTime',
        'totalSize',
        'backupServerReference'
    ];

    private static fields = ODataBckpVbrJobs.selectedFields.join(',');
    private _filter: VeeamJobsFilter;
    private field4Filter: string[] = [];
    get filter ()
    {
        return this._filter;
    }
    get since ()
    {
        return this._filter.since;
    }
    get until ()
    {
        return this._filter.until;
    }
    public datesService: DatesService;
    public qsExport: string;
    public qsSinceUntil: string;

    constructor (incrSize: number, filter: VeeamJobsFilter, nbItems?: number)
    {
        super(incrSize, nbItems);

        const injector = Injector.create({ providers: [{ provide: DatesService, deps: [] }] });
        this.datesService = injector.get(DatesService);

        this.exportValues = [{ key: 'tags', value: 'true' }];

        if (filter !== undefined)
        {
            this.processFilter(filter);
        }

        // Options de tri
        this.values.push({ key: '$orderby', value: 'started:desc' });
        // this.values.push({ key: '$orderby', value: 'tries.statusCount:desc' });

        // Sélection des champs
        this.values.push({ key: '$select', value: ODataBckpVbrJobs.fields });

        this.qs = this.getQueryString();

        this.qsExport = this.getExportQueryString();
    }

    public init ()
    {
        this.processFilter(new VeeamJobsFilter());
    }

    private processFilter (filter: VeeamJobsFilter)
    {
        this._filter = new VeeamJobsFilter();
        Object.assign(this._filter, filter);

        this.pushValue('since', filter.since);
        this.pushValue('until', filter.until);

        this.qsSinceUntil = `?since=${filter.since}&until=${filter.until}`;


        this.field4Filter.forEach((f: string) =>
        {
            if (filter[f])
            {
                this.pushValue('terms', `${f}:${filter[f]}`);
            }
        });
    }

    private getExportQueryString (): string
    {
        const queries: string[] = [];
        let qs: string = '?';

        this.exportValues
            .forEach((kv: { key: string, value: any }) =>
            {
                queries.push(`${kv.key}=${kv.value}`);
            });
        qs += queries.join('&');

        return qs;
    }
}

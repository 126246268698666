import { HttpEvent } from '@angular/common/http';

import { createAction, props } from '@ngrx/store';

import { ResultDomain } from '@common/domain/result-domain';
import { VeeamJob } from '@apps/bckp/domain/models/veeamJob';
import { VeeamJobsFilter } from '@apps/bckp/models/veeam-jobs-filter';
import { MediaTypes } from '@common/services';

export const BckpVbrJobsODataInitialized = createAction(
    '[BCKP-JOBS] Bckp Vbr Jobs OData Initialized',
    props<{ scrollSize: number, filter: VeeamJobsFilter }>()
);

export const BckpVbrJobsODataRequested = createAction(
    '[BCKP-JOBS] Bckp Vbr Jobs OData Requested'
);

export const BckpVbrJobsODataLoaded = createAction(
    '[BCKP-JOBS] Bckp Vbr Jobs OData Loaded',
    props<{ dataLoaded: ResultDomain<VeeamJob> }>()
);

export const BckpVbrJobsODataFailed = createAction(
    '[BCKP-JOBS] Bckp Vbr Jobs OData Failed'
);

export const BckpVbrJobsODataIncremented = createAction(
    '[BCKP-JOBS] Bckp Vbr Jobs OData Incremented'
);

export const BckpVbrJobsFilterApplied = createAction(
    '[BCKP-JOBS] Bckp Vbr Jobs Filter Applied',
    props<{ filter: VeeamJobsFilter }>()
);

export const BckpVbrJobsCleared = createAction(
    '[BCKP-JOBS] Backup Vbr Jobs Cleared'
);

export const BckpVbrJobsQsWordSet = createAction(
    '[BCKP-JOBS] Bckp Vbr Jobs QsWord Set',
    props<{ qsWord: string }>()
);

export const BckpVbrJobsExportRequested = createAction(
    '[BCKP-JOBS] Bckp Vbr Jobs Export Requested',
    props<{ format: MediaTypes }>()
);

export const BckpVbrJobsExportSucceeded = createAction(
    '[BCKP-JOBS] Bckp Vbr Jobs Export Succeeded',
    props<{ response: HttpEvent<Blob> }>()
);

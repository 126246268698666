import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';

import { SelectOption } from '@ui/components/form/common';
import { OpenPopinOriginator } from '@common/enum/open-popin-originator.enum';
import { TypePopin } from '@apps/app.constants';
import {
    ActivityByServer,
    CapacityGbytesPerServer,
    ImageCopy,
    Job,
    Policy,
    Server
} from './domain/models';
import { CmdbConstants } from '@apps/cmdb/cmdb.constants';

export enum Its2EndpointGuid {
    Server = 'F985E151-C508-45FB-8064-F3F88AAE9A76',
    Policy = '87A4B4F3-18D4-4710-97BC-30251004825B'
}
export enum PolicyStatus {
    Active = 0,
    Inactive = 1
}
export enum JobsStatus {
    Success = 0,
    Partial = 1,
    Failed = 2,
    Running = 3
}

export enum JobsState {
    Done = 0,
    Running = 1
}

export enum JobsContract {
    NotRegulated = 0,
    Regulated = 1
}

export enum ServersScheduleType {
    Incr = 0,
    Full = 1
}

export const BckpFeatureKey = 'bckp';

export class BckpConstants
{
    public static Application: string = 'BckpApi';
    public static BaseApi: string = 'hist';

    // Durée de vie en minutes des éléments dans le store
    public static DataLifeTime: number = 10;

    // Route names
    public static Bckp: string = 'backup';
    public static BackupSystems: string = 'backupsystems';
    public static Vbr: string = 'vbr';
    public static Dashboard: string = 'dashboard';
    public static Jobs: string = 'jobs';
    public static Servers: string = 'servers';
    public static Serv: string = 'serv';
    public static Heat: string = 'heat';
    public static Capacity: string = 'capacity';
    public static Policies: string = 'policies';
    public static Copies: string = 'copies';
    public static Evol: string = 'evolution';
    public static Curr: string = 'outstanding';
    public static Details: string = 'details';
    public static Filter: string = 'filter';
    public static DefaultUrl: string = `/${BckpConstants.Bckp}/${BckpConstants.BackupSystems}`;

    public static jobsQSFields: Array<keyof Job> = ['metaStatus',
        'started', 'elapsed', 'serverShort', 'master', 'policyName',
        'policyType', 'errorDesc', 'tagFullNames'];
    public static capacitiesQSFields: Array<keyof CapacityGbytesPerServer> = ['serverShort', 'tagFullNames'];
    public static copiesQSFields: Array<keyof ImageCopy> = ['server', 'master', 'policyName'];
    public static policiesQSFields: Array<keyof Policy> = ['metaStatusKey',
        'policyName', 'serversShortList', 'master', 'policyType', 'tagFullNames'];
    public static serversServQSFields: Array<keyof Server> = ['server', 'serverShort',
        'master', 'policyName', 'policyType', 'scheduleName',
        'scheduleType', 'ageBackup', 'futureExpiration', 'tagFullNames'];
    public static serversHeatQSFields: Array<keyof ActivityByServer> = ['server', 'master', 'tagFullNames'];
    // Capacities
    public static TotalColumnName: string = 'Total';

    // Copy
    public static RemoteMaster: string = '*Remote*Master*';

    // Planification
    public static Platinum: string = 'Platinum';
    public static Gold: string = 'Gold';
    public static Silver: string = 'Silver';
    public static Bronze: string = 'Bronze';
    public static PlanificationLevels = [
        BckpConstants.Platinum,
        BckpConstants.Gold,
        BckpConstants.Silver,
        BckpConstants.Bronze
    ];

    // Restore
    public static Files: string = 'BACKUP.INPUTITEMS.RESTORE.TYPE.FILES';
    public static Vms: string = 'BACKUP.INPUTITEMS.RESTORE.TYPE.VMS';
    public static DataBases: string = 'BACKUP.INPUTITEMS.RESTORE.TYPE.DATABASES';
    public static RestoreTypes = [
        BckpConstants.Files,
        BckpConstants.Vms,
        BckpConstants.DataBases
    ];

    // ************************ Business Rules ************************ //
    public static BeginningOfTime = DateTime.utc(2018).startOf('year');
    // Nombre de jobs recherchés à chaque appel serveur $top=...
    public static QSJobsTop: number = 500;
    public static QSServersTop: number = 50; // Non pris en compte par le backend
    public static QSCopiesTop = 0;
    public static SLA: number = 95;

    public static NbServerPerChargedCapacity: number = 100;

    // Classement des jobs par code status
    public static JobsMetaStatus = new Map([
        [0, 'STATUSES.SUCCESS'],
        [1, 'STATUSES.PARTIAL'],
        [2, 'STATUSES.FAILED'],
        [3, 'STATUSES.RUNNING']
    ]);
    public static JobsState = new Map([
        [0, 'STATUSES.DONE'],
        [1, 'STATUSES.RUNNING']
    ]);
    // public static DoneState: number = 0;
    // public static RunningState: number = 1;
    public static getCssStatus = ['success', 'partial', 'failed', 'running'];

    // Servers: Liste des scheduleType
    public static ServersMetaScheduleTypes = new Map([
        [0, 'BACKUP.SERVERS.FILTER.SCHEDULETYPESMETA.INCR'],
        [1, 'BACKUP.SERVERS.FILTER.SCHEDULETYPESMETA.FULL']
    ]);

    public static PolicyMetaActiveStatus = new Map([
        [0, 'STATUSES.ACTIVE'],
        [1, 'STATUSES.INACTIVE']
    ]);

    public static NetBackupSystemId: string = '36e895b8-cc19-4166-a258-45253c805587';
    public static VbrSystemId: string = 'b4aa2ff1-8799-4c87-bef0-387e61bfc74a';
    public static VboSystemId: string = '5eb327ac-d925-40dc-9efe-8bbe11a19bf5';

    public static getCssActiveStatus = (status: boolean) => status ? 'active' : 'inactive';
    public static getCodeStatus = (s: number) => s > 1 ? 2 : s;

    public static getStatusOptions (translateService: TranslateService): SelectOption[]
    {
        const options: SelectOption[] = [];

        BckpConstants.JobsMetaStatus.forEach((value, key) =>
            options.push(new SelectOption(key, translateService.instant(value), true))
        );

        return options.slice(0, 3);
    }

    public static getStateOptions (translateService: TranslateService): SelectOption[]
    {
        const options: SelectOption[] = [];

        BckpConstants.JobsState.forEach((value, key) =>
            options.push(new SelectOption(key, translateService.instant(value), false))
        );

        return options;
    }

    public static getContractOptions (translateService: TranslateService): SelectOption[]
    {
        const options: SelectOption[] = [];

        options.push(
            new SelectOption(JobsContract.Regulated, translateService.instant('BACKUP.JOBS.FILTER.REGULATED.REGULATED'), false)
        );
        options.push(
            new SelectOption(JobsContract.NotRegulated, translateService.instant('BACKUP.JOBS.FILTER.REGULATED.NOTREGULATED'), false)
        );

        return options;
    }

    // Servers: scheduleType filter
    public static getScheduleTypeOptions (translateService: TranslateService): SelectOption[]
    {
        const options: SelectOption[] = [];

        BckpConstants.ServersMetaScheduleTypes.forEach((value, key) =>
            options.push(new SelectOption(key, translateService.instant(value), true))
        );

        return options;
    }

    // Policies
    public static getActiveStatusOptions (translateService: TranslateService): SelectOption[]
    {
        const options: SelectOption[] = [];

        BckpConstants.PolicyMetaActiveStatus.forEach((value, key) =>
            options.push(new SelectOption(key, translateService.instant(value), true))
        );

        return options;
    }

    public static DashboardRoute = () =>
        [
            BckpConstants.Bckp,
            BckpConstants.Dashboard
        ];

    public static CreateCaseRoute = (openPopinOriginator: OpenPopinOriginator) =>
        [
            ...BckpConstants.DashboardRoute(),
            { outlets: { [TypePopin.ACTION]: [ 'createCase', openPopinOriginator ] } }
        ];

    public static ToPoliciesRoute = () =>
        [
            BckpConstants.Bckp,
            BckpConstants.Policies
        ];

    public static ToPoliciesDetailsRoute = (documentId: string) =>
        [
            BckpConstants.Bckp,
            BckpConstants.Policies,
            { outlets: { [TypePopin.DETAILS]: [OpenPopinOriginator.BCKP_POLICIES, documentId] } }
        ];

    // #region Onglet Servers
    public static ServersRoute =
    [
        BckpConstants.Bckp,
        BckpConstants.Servers
    ];

    public static ServersServDetailsRoute = (serverShort: string) =>
        [
            BckpConstants.Bckp,
            BckpConstants.Servers,
            BckpConstants.Serv,
            { outlets: { [TypePopin.DETAILS]: [BckpConstants.Serv, serverShort] } }
        ];

    public static ServersServFilterRoute = () =>
        [
            BckpConstants.Bckp,
            BckpConstants.Servers,
            BckpConstants.Serv,
            { outlets: { [TypePopin.FILTER]: [BckpConstants.Filter] } }
        ];

    public static ServersServToTagCreationRoute = () =>
        [
            BckpConstants.Bckp,
            CmdbConstants.Tags,
            { outlets: { [TypePopin.ACTION]: ['create', OpenPopinOriginator.BCKP_SERVERS] } }
        ];

    public static ServersHeatServerDetailsRoute = (serverShort: string) =>
        [
            BckpConstants.Bckp,
            BckpConstants.Servers,
            BckpConstants.Heat,
            { outlets: { [TypePopin.DETAILS]: [BckpConstants.Heat, 'server', serverShort] } }
        ];

    public static ServersHeatToTagCreationRoute = () =>
        [
            BckpConstants.Bckp,
            CmdbConstants.Tags,
            { outlets: { [TypePopin.ACTION]: ['create', OpenPopinOriginator.BCKP_HEATS] } }
        ];

    public static ServersHeatJobDetailsRoute = (documentId: string) =>
        [
            BckpConstants.Bckp,
            BckpConstants.Servers,
            BckpConstants.Heat,
            { outlets: { [TypePopin.DETAILS]: [BckpConstants.Serv, 'job', documentId] } }
        ];

    public static ServersHeatFilterRoute = () =>
        [
            BckpConstants.Bckp,
            BckpConstants.Servers,
            BckpConstants.Heat,
            { outlets: { [TypePopin.FILTER]: [BckpConstants.Filter] } }
        ];
    public static ServersServListRoute: string[] =
    [
        ...BckpConstants.ServersRoute,
        BckpConstants.Serv
    ];
    public static ServersHeatListRoute: string[] =
    [
        ...BckpConstants.ServersRoute,
        BckpConstants.Heat
    ];
    public static ServersListPath = `/${BckpConstants.ServersServListRoute.join('/')}`;
    public static ServersHeatRoute: string[] =
    [
        ...BckpConstants.ServersRoute,
        BckpConstants.Heat
    ];
    public static ServersHeatPath = `/${BckpConstants.ServersHeatRoute.join('/')}`;
    public static ServersPathRegexp = ['', ...BckpConstants.ServersRoute].join('/');
    // #endregion

    // #region Onglet Copies
    private static CopiesRoute =
    [
        BckpConstants.Bckp,
        BckpConstants.Copies
    ];
    public static CopiesEvolRoute: string[] =
    [
        ...BckpConstants.CopiesRoute,
        BckpConstants.Evol
    ];
    public static CopiesEvolPath = `/${BckpConstants.CopiesEvolRoute.join('/')}`;
    public static CopiesCurrRoute: string[] =
    [
        ...BckpConstants.CopiesRoute,
        BckpConstants.Curr
    ];
    public static CopiesCurrPath = `/${BckpConstants.CopiesCurrRoute.join('/')}`;
    public static CopiesPathRegexp = ['', ...BckpConstants.CopiesRoute].join('/');
    // #endregion

    // #region Onglet Jobs
    public static JobsRoute =
    [
        BckpConstants.Bckp,
        BckpConstants.Jobs
    ];

    public static JobDetailsRoute = (documentId: string) =>
        [
            BckpConstants.Bckp,
            BckpConstants.Jobs,
            { outlets: { [TypePopin.DETAILS]: [BckpConstants.Jobs, documentId] } }
        ];

    public static JobsFilterRoute = () =>
        [
            BckpConstants.Bckp,
            BckpConstants.Jobs,
            { outlets: { [TypePopin.FILTER]: [BckpConstants.Filter] } }
        ];

    public static VbrJobsFilterRoute = () =>
        [
            BckpConstants.Bckp,
            BckpConstants.Vbr,
            BckpConstants.Jobs,
            { outlets: { [TypePopin.FILTER]: [BckpConstants.Filter] } }
        ];
    public static JobsListPath = `/${BckpConstants.JobsRoute.join('/')}`;
    //#endregion

    //#region Onglet capacity
    public static CapacitiesToTagCreationRoute = () =>
        [
            BckpConstants.Bckp,
            CmdbConstants.Tags,
            { outlets: { [TypePopin.ACTION]: ['create', OpenPopinOriginator.BCKP_CAPACITIES] } }
        ];

    public static CapacitiesRoute: string[] =
    [
        BckpConstants.Bckp,
        BckpConstants.Capacity
    ];

    public static CapacitiesPath = `/${BckpConstants.CapacitiesRoute.join('/')}`;
    //#endregion
}

import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import { DateTime } from 'luxon';

import { Comparer, filterQuickSearchWithTags } from '@common/services/utils.service';
import { BckpConstants, BckpFeatureKey } from '@apps/bckp/bckp.constants';
import { FilterTag } from '@apps/cmdb/models';
import { getFilterTags } from '@apps/root/store';

import { IAuroraState } from '../../aurora.state';
import * as fromDashboardReducer from './dashboard/dashboard.reducer';
import * as fromJobsReducer from './jobs/jobs.reducer';
import * as fromServersReducer from './servers/servers.reducer';
import * as fromOptionsReducer from './common/options/options.reducer';
import * as fromCapacitiesReducer from './capacities/capacities.reducer';
import * as fromPoliciesReducer from './policies/policies.reducer';
import * as fromCopiesReducer from './copies/copies.reducer';
import * as fromBackupSystemsReducer from './common/backup-systems/backup-systems.reducer';
import * as fromVbrDashboardReducer from './veeam/vbr/vbr-dashboard/vbr-dashboard.reducer';
import * as fromVboDashboardReducer from './veeam/vbo/vbo-dashboard/vbo-dashboard.reducer';
import { JobDetails, CapacityGbytesPerServer, Job, PolicyForFilter } from '../domain/models';
import { IBckpJobsState } from './jobs/jobs.reducer';
import * as fromVbrJobsReducer from './veeam/vbr/jobs/vbr-jobs.reducer';
import { VeeamJob } from '../domain/models/veeamJob';
import { IBckpVbrJobsState } from './veeam/vbr/jobs/vbr-jobs.reducer';

export interface IBckpState
{
    [fromDashboardReducer.BckpDashboardFeatureKey]: fromDashboardReducer.IBckpDashboardState;
    [fromJobsReducer.BckpJobsFeatureKey]: fromJobsReducer.IBckpJobsState;
    [fromServersReducer.BckpServersFeatureKey]: fromServersReducer.IBckpServersState;
    [fromOptionsReducer.BckpOptionsFeatureKey]: fromOptionsReducer.IBckpOptionsState;
    [fromCapacitiesReducer.BckpCapacitiesFeatureKey]: fromCapacitiesReducer.IBckpCapacitiesState;
    [fromPoliciesReducer.BckpPoliciesFeatureKey]: fromPoliciesReducer.IBckpPoliciesState;
    [fromCopiesReducer.BckpCopiesFeatureKey]: fromCopiesReducer.IBckpCopiesState;
    [fromBackupSystemsReducer.BckpBackupSystemsFeatureKey]: fromBackupSystemsReducer.IBckpBackupSystemsState;
    [fromVbrDashboardReducer.BckpVbrDashboardFeatureKey]: fromVbrDashboardReducer.IBckpVbrDashboardState;
    [fromVboDashboardReducer.BckpVboDashboardFeatureKey]: fromVboDashboardReducer.IBckpVboDashboardState;
    [fromVbrJobsReducer.BckpVbrJobsFeatureKey]: fromVbrJobsReducer.IBckpVbrJobsState;

}

export interface IState extends IAuroraState
{
    [BckpFeatureKey]: IBckpState;
}

export function BckpReducers (state: IBckpState | undefined, action: Action)
{
    return combineReducers({
        [fromDashboardReducer.BckpDashboardFeatureKey]: fromDashboardReducer.DashboardReducer,
        [fromJobsReducer.BckpJobsFeatureKey]: fromJobsReducer.BckpJobsReducer,
        [fromServersReducer.BckpServersFeatureKey]: fromServersReducer.BckpServersReducers,
        [fromOptionsReducer.BckpOptionsFeatureKey]: fromOptionsReducer.BckpOptionsReducer,
        [fromCapacitiesReducer.BckpCapacitiesFeatureKey]: fromCapacitiesReducer.CapacitiesReducer,
        [fromPoliciesReducer.BckpPoliciesFeatureKey]: fromPoliciesReducer.BckpPoliciesReducer,
        [fromCopiesReducer.BckpCopiesFeatureKey]: fromCopiesReducer.BckpCopiesReducers,
        [fromBackupSystemsReducer.BckpBackupSystemsFeatureKey]: fromBackupSystemsReducer.BckpBackupSystemsReducer,
        [fromVbrDashboardReducer.BckpVbrDashboardFeatureKey]: fromVbrDashboardReducer.VbrDashboardReducer,
        [fromVboDashboardReducer.BckpVboDashboardFeatureKey]: fromVboDashboardReducer.VboDashboardReducer,
        [fromVbrJobsReducer.BckpVbrJobsFeatureKey]: fromVbrJobsReducer.BckpVbrJobsReducer
    })(state, action);
}

export const getBckpState = createFeatureSelector<IBckpState>(
    BckpFeatureKey
);

const getBckpDashboardState = createSelector(
    getBckpState,
    (state: IBckpState) =>
        state[fromDashboardReducer.BckpDashboardFeatureKey]
);

// #region Last Session
export const getBckpDashboardLastSession = createSelector(
    getBckpDashboardState,
    (state: fromDashboardReducer.IBckpDashboardState) =>
        state.lastSession
);

export const getBckpDashboardLastSessionIsDataValid = createSelector(
    getBckpDashboardState,
    (state: fromDashboardReducer.IBckpDashboardState) =>
        state.lastSession.expirationDate !== null && DateTime.utc() < state.lastSession.expirationDate
);
// #endregion

// #region Status Per Range
export const getBckpDashboardStatusPerRange = createSelector(
    getBckpDashboardState,
    (state: fromDashboardReducer.IBckpDashboardState) =>
        state.statusPerRange
);

export const getBckpDashboardStatusPerRangeIsDataValid = createSelector(
    getBckpDashboardState,
    (state: fromDashboardReducer.IBckpDashboardState) =>
        state.statusPerRange.expirationDate !== null && DateTime.utc() < state.statusPerRange.expirationDate
);
// #endregion

// #region Ticketing
export const getBckpDashboardTicketing = createSelector(
    getBckpDashboardState,
    (state: fromDashboardReducer.IBckpDashboardState) =>
        state.ticketing
);

// export const getBckpDashboardTicketingIsDataValid = createSelector(
//     getBckpDashboardState,
//     (state: fromDashboardReducer.IBckpDashboardState) =>
//         state.ticketing.validUntil !== null && DateTime.utc() < state.ticketing.validUntil
// );
// #endregion

// #region Status Per Policy Type
export const getBckpDashboardStatusPerPolicyType = createSelector(
    getBckpDashboardState,
    (state: fromDashboardReducer.IBckpDashboardState) =>
        state.statusPerPolicyType
);

export const getBckpDashboardStatusPerPolicyTypeIsDataValid = createSelector(
    getBckpDashboardState,
    (state: fromDashboardReducer.IBckpDashboardState) =>
        state.statusPerPolicyType.validUntil !== null && DateTime.utc() < state.statusPerPolicyType.validUntil
);
// #endregion

// #region VolumetricVariations
export const getBckpDashboardVariations = createSelector(
    getBckpDashboardState,
    (state: fromDashboardReducer.IBckpDashboardState) =>
        state.variations
);

export const getBckpDashboardVariationsIsDataValid = createSelector(
    getBckpDashboardState,
    (state: fromDashboardReducer.IBckpDashboardState) =>
        state.variations.validUntil !== null && DateTime.utc() < state.variations.validUntil
);
// #endregion

// #region TotalCapacity
export const getBckpDashboardTotalCapacity = createSelector(
    getBckpDashboardState,
    (state: fromDashboardReducer.IBckpDashboardState) =>
        state.totalCapacity
);

export const getBckpDashboardTotalCapacityIsDataValid = createSelector(
    getBckpDashboardState,
    (state: fromDashboardReducer.IBckpDashboardState) =>
        state.totalCapacity.validUntil !== null && DateTime.utc() < state.totalCapacity.validUntil
);
// #endregion

// #region CountServers
export const getBckpDashboardCountServers = createSelector(
    getBckpDashboardState,
    (state: fromDashboardReducer.IBckpDashboardState) =>
        state.countServers
);

export const getBckpDashboardCountServersIsDataValid = createSelector(
    getBckpDashboardState,
    (state: fromDashboardReducer.IBckpDashboardState) =>
        state.countServers.validUntil !== null && DateTime.utc() < state.countServers.validUntil
);
// #endregion

// #region Jobs
const getBckpJobsState = createSelector(
    getBckpState,
    (state: IBckpState): IBckpJobsState =>
        state[fromJobsReducer.BckpJobsFeatureKey]
);
export const getBckpJobsQueryString = createSelector(
    getBckpJobsState,
    (state: fromJobsReducer.IBckpJobsState) =>
        state.oData.qs
);
// export const getBckpJobsFullSearchQueryString = createSelector(
//     getBckpJobsState,
//     (state: fromJobsReducer.IBckpJobsState) =>
//         state.oDataSearch.qs
// );
export const getBckpJobsODataReady = createSelector(
    getBckpJobsState,
    (state: fromJobsReducer.IBckpJobsState) =>
        state.oData.qs !== null && state.oData.qs.includes('since') && state.oData.qs.includes('until')
);

export const getBckpJobsExportQueryString = createSelector(
    getBckpJobsState,
    (state: fromJobsReducer.IBckpJobsState) =>
        state.oData.qsExport
);
export const getBckpJobsFilter = createSelector(
    getBckpJobsState,
    (state: fromJobsReducer.IBckpJobsState) =>
        state.oData.filter
);
export const getBckpJobsSinceUntil = createSelector(
    getBckpJobsState,
    (state: fromJobsReducer.IBckpJobsState) =>
        state.oData.qsSinceUntil
);
export const getBckpJobsCollectionState = createSelector(
    getBckpJobsState,
    (state: fromJobsReducer.IBckpJobsState) => state.collection
);

export const getBckpJobsEndReached = createSelector(
    getBckpJobsState,
    (state: fromJobsReducer.IBckpJobsState) => state.oData.isEndReached
);

export const {
    selectAll: getAllJobs,
} = fromJobsReducer.jobsAdapter.getSelectors(getBckpJobsCollectionState);

export const getQuickSearchedJobs = createSelector(
    getBckpJobsState,
    getAllJobs,
    getFilterTags,
    (state: fromJobsReducer.IBckpJobsState, jobs: Job[], filterTags: FilterTag[]): Job[] =>
        filterQuickSearchWithTags(jobs, state.qsWord, BckpConstants.jobsQSFields, filterTags)
);

export const getPipelinedJobs = (comparer: Comparer<Job>) => createSelector(
    getQuickSearchedJobs,
    (jobs: Job[]): Job[] =>
        jobs.sort(comparer)
);
export const getBckpJobsLoadingStatus = createSelector(
    getBckpJobsState,
    (state: fromJobsReducer.IBckpJobsState) => state.isLoading
);
export const getBckpJobsLoadedStatus = createSelector(
    getBckpJobsState,
    (state: fromJobsReducer.IBckpJobsState) => state.isLoaded
);
export const getBckpJobsEmptyStatus = createSelector(
    getBckpJobsState,
    (state: fromJobsReducer.IBckpJobsState) => state.isEmpty
);
export const getBckpJobsOupsStatus = createSelector(
    getBckpJobsState,
    (state: fromJobsReducer.IBckpJobsState) => state.isOups
);
export const getBckpJobsNbLoaded = createSelector(
    getBckpJobsState,
    (state: fromJobsReducer.IBckpJobsState) => state.oData.nbItems
);
export const getAllJobsByIdentifier = createSelector(
    getAllJobs,
    (jobs: Job[], params: { identifier: string; }) =>
        jobs.filter((job: Job) => job.assetName() === params.identifier)
);
// #endregion

// #region Detailed Jobs
export const getBckpJobsDetailsState = createSelector(
    getBckpJobsState,
    (state: fromJobsReducer.IBckpJobsState) => state.detailedJobs
);
export const getBckpJobsDetailsSelectedJob = createSelector(
    getBckpJobsState,
    (state: fromJobsReducer.IBckpJobsState) => state.selectedJob
);
export const {
    selectAll: getAllDetailedJobs,
} = fromJobsReducer.jobsDetailsAdapter.getSelectors(getBckpJobsDetailsState);

export const getBckpJobDetailsById = createSelector(
    getAllDetailedJobs,
    (jobs: JobDetails[], props: { storeId: string }) =>
        jobs.filter((j: JobDetails) => j.storeId === props.storeId)
);

export const getBckpSelectedJobDetails = createSelector(
    getBckpJobsState,
    (state) =>
        state.selectedJob
);
// #endregion

// #region Detailed Server
// export const getBckpServerDetailsById = createSelector(
//     getAllDetailedServers,
//     (servers: ServerDetails[], props: { storeId: string }) =>
//         servers.filter((j: ServerDetails) => j.storeId === props.storeId)
// );

// #endregion

// #region Capacities
const getBckpCapacitiesState = createSelector(
    getBckpState,
    (state: IBckpState) =>
        state[fromCapacitiesReducer.BckpCapacitiesFeatureKey]
);

export const getBckpCapacitiesLoadingStatus = createSelector(
    getBckpCapacitiesState,
    (state): boolean => state.isLoading
);

export const getBckpCapacitiesIsLoadedStatus = createSelector(
    getBckpCapacitiesState,
    (state): boolean => state.isLoaded
);

export const getBckpCapacitiesIsEmptyStatus = createSelector(
    getBckpCapacitiesState,
    (state): boolean => state.isEmpty
);

export const getBckpCapacitiesODataReady = createSelector(
    getBckpCapacitiesState,
    (state) =>
        state.oData.qs !== null && state.oData.qs.includes('since') && state.oData.qs.includes('until')
);

export const getBckpCapacitiesFilter = createSelector(
    getBckpCapacitiesState,
    (state) =>
        state.oData.filter
);

export const getBckpCapacitiesArraySinceUntil = createSelector(
    getBckpCapacitiesState,
    (state) =>
        [
            state.oData.since,
            state.oData.until
        ]
);

export const getBckpCapacitiesArrayRangeSinceUntil = createSelector(
    getBckpCapacitiesState,
    (state) =>
        [
            state.oData.filter.rangeSince,
            state.oData.filter.rangeUntil
        ]
);
export const getBckpCapacitiesExportQueryString = createSelector(
    getBckpCapacitiesState,
    (state) =>
        state.oData.qsExport
);

const getBckpCapacitiesCollectionState = createSelector(
    getBckpCapacitiesState,
    (state: fromCapacitiesReducer.IBckpCapacitiesState) => state.collection
);
const {
    selectAll: getFullChargedCapacities,
} = fromCapacitiesReducer.chargedCapacitiesAdapter.getSelectors(getBckpCapacitiesCollectionState);

const getFilterFlags = createSelector(
    getBckpCapacitiesState,
    (state) =>
        state.stateFilterFlags
);
export const getChargedCapacities = createSelector(
    getFullChargedCapacities,
    getFilterFlags,
    (capacities, filterFlags) =>
        // tslint:disable-next-line: no-bitwise
        capacities.filter((item: CapacityGbytesPerServer) => (item.policyFlags & filterFlags) !== 0)
);
export const getBckpCapacitiesPolicies = createSelector(
    getBckpCapacitiesState,
    (state) =>
        state.policyForFilters
);
export const getQuickSearchedCapacities = createSelector(
    getBckpCapacitiesState,
    getChargedCapacities,
    getFilterTags,
    (state: fromCapacitiesReducer.IBckpCapacitiesState, capacities: CapacityGbytesPerServer[], filterTags: FilterTag[]) =>
        filterQuickSearchWithTags(capacities, state.qsWord, BckpConstants.capacitiesQSFields, filterTags)
);
export const getBckpCapacitiesGrandTotal = createSelector(
    getQuickSearchedCapacities,
    getBckpCapacitiesPolicies,
    (capacityGbytesPerServers: CapacityGbytesPerServer[], policies: PolicyForFilter[]): CapacityGbytesPerServer =>
    {
        const grandTotal: number[] = capacityGbytesPerServers
            .map((cgps: CapacityGbytesPerServer): number[] => cgps.gbytes)
            .reduce((prev: number[], curr: number[]): number[] => prev.map((v: number, idx): number => v + (curr[idx] || 0)), Array(policies.length).fill(0));

        return new CapacityGbytesPerServer(BckpConstants.TotalColumnName, grandTotal);
    }
);

export const getPipelinedCapacities = (comparer: Comparer<CapacityGbytesPerServer>) => createSelector(
    getQuickSearchedCapacities,
    (capacities: CapacityGbytesPerServer[]) =>
        capacities.sort(comparer)
);
export const getBckpCapacitiesLoadedStatus = createSelector(
    getBckpCapacitiesState,
    (state) => state.isLoaded
);
export const getAllCapacitiesByIdentifier = (identifier: string) => createSelector(
    getFullChargedCapacities,
    (capacities: CapacityGbytesPerServer[]) =>
        capacities.filter((capacity: CapacityGbytesPerServer) =>
            capacity.assetName() === identifier)
);
// #endregion

const getBckpBackupSystemsState = createSelector(
    getBckpState,
    (state: IBckpState) =>
        state[fromBackupSystemsReducer.BckpBackupSystemsFeatureKey]
);
export const getSelectedBackupSystem = createSelector(
    getBckpBackupSystemsState,
    (state) => state.selectedBackupSystem
);

// #region Veeam BR

const getBckpVbrDashboardState = createSelector(
    getBckpState,
    (state: IBckpState) =>
        state[fromVbrDashboardReducer.BckpVbrDashboardFeatureKey]
);
export const getBckpVbrDashboardStatusPerRange = createSelector(
    getBckpVbrDashboardState,
    (state: fromVbrDashboardReducer.IBckpVbrDashboardState) =>
        state.statusPerRange
);

export const getBckpVbrDashboardStatusPerRangeIsDataValid = createSelector(
    getBckpVbrDashboardState,
    (state: fromVbrDashboardReducer.IBckpVbrDashboardState) =>
        state.statusPerRange.expirationDate !== null && DateTime.utc() < state.statusPerRange.expirationDate
);

export const getBckpVbrDashboardLastSession = createSelector(
    getBckpVbrDashboardState,
    (state: fromVbrDashboardReducer.IBckpVbrDashboardState) =>
        state.lastSession
);

export const getBckpVbrDashboardLastSessionIsDataValid = createSelector(
    getBckpVbrDashboardState,
    (state: fromVbrDashboardReducer.IBckpVbrDashboardState) =>
        state.lastSession.expirationDate !== null && DateTime.utc() < state.lastSession.expirationDate
);

export const getBckpVbrDashboardCountServers = createSelector(
    getBckpVbrDashboardState,
    (state: fromVbrDashboardReducer.IBckpVbrDashboardState) =>
        state.countServers
);

export const getBckpVbrDashboardCountServersIsDataValid = createSelector(
    getBckpVbrDashboardState,
    (state: fromVbrDashboardReducer.IBckpVbrDashboardState) =>
        state.countServers.validUntil !== null && DateTime.utc() < state.countServers.validUntil
);

export const getBckpVbrDashboardTotalCapacity = createSelector(
    getBckpVbrDashboardState,
    (state: fromVbrDashboardReducer.IBckpVbrDashboardState) =>
        state.totalCapacity
);

export const getBckpVbrDashboardTotalCapacityIsDataValid = createSelector(
    getBckpVbrDashboardState,
    (state: fromVbrDashboardReducer.IBckpVbrDashboardState) =>
        state.totalCapacity.validUntil !== null && DateTime.utc() < state.totalCapacity.validUntil
);

export const getBckpVbrDashboardTicketing = createSelector(
    getBckpVbrDashboardState,
    (state: fromVbrDashboardReducer.IBckpVbrDashboardState) =>
        state.ticketing
);

export const getBckpVbrDashboardVariations = createSelector(
    getBckpVbrDashboardState,
    (state: fromVbrDashboardReducer.IBckpVbrDashboardState) =>
        state.variations
);

export const getBckpVbrDashboardVariationsIsDataValid = createSelector(
    getBckpVbrDashboardState,
    (state: fromVbrDashboardReducer.IBckpVbrDashboardState) =>
        state.variations.validUntil !== null && DateTime.utc() < state.variations.validUntil
);

const getBckpVbrJobsState = createSelector(
    getBckpState,
    (state: IBckpState): IBckpVbrJobsState =>
        state[fromVbrJobsReducer.BckpVbrJobsFeatureKey]
);

export const getBckpVbrJobsCollectionState = createSelector(
    getBckpVbrJobsState,
    (state: fromVbrJobsReducer.IBckpVbrJobsState) => state.collection
);

export const {
    selectAll: getAllVbrJobs,
} = fromVbrJobsReducer.vbrJobsAdapter.getSelectors(getBckpVbrJobsCollectionState);

export const getPipelinedVbrJobs = (comparer: Comparer<VeeamJob>) => createSelector(
    getAllVbrJobs,
    (jobs: VeeamJob[]): VeeamJob[] =>
        jobs.sort(comparer)
);

export const getBckpVbrJobsODataReady = createSelector(
    getBckpVbrJobsState,
    (state: fromVbrJobsReducer.IBckpVbrJobsState) =>
        state.oData.qs !== null && state.oData.qs.includes('since') && state.oData.qs.includes('until')
);

export const getBckpVbrJobsFilter = createSelector(
    getBckpVbrJobsState,
    (state: fromVbrJobsReducer.IBckpVbrJobsState) =>
        state.oData.filter
);

export const getBckpVbrJobsExportQueryString = createSelector(
    getBckpVbrJobsState,
    (state: fromVbrJobsReducer.IBckpVbrJobsState) =>
        state.oData.qsExport
);

export const getBckpVbrJobsLoadingStatus = createSelector(
    getBckpVbrJobsState,
    (state: fromVbrJobsReducer.IBckpVbrJobsState) => state.isLoading
);

export const getBckpVbrJobsOupsStatus = createSelector(
    getBckpVbrJobsState,
    (state: fromVbrJobsReducer.IBckpVbrJobsState) => state.isOups
);

export const getBckpVbrJobsLoadedStatus = createSelector(
    getBckpVbrJobsState,
    (state: fromVbrJobsReducer.IBckpVbrJobsState) => state.isLoaded
);

export const getBckpVbrJobsEndReached = createSelector(
    getBckpVbrJobsState,
    (state: fromVbrJobsReducer.IBckpVbrJobsState) => state.oData.isEndReached
);

export const getBckpVbrJobsQueryString = createSelector(
    getBckpVbrJobsState,
    (state: fromVbrJobsReducer.IBckpVbrJobsState) =>
        state.oData.qs
);

// #endregion

// #region Veeam BO

const getBckpVboDashboardState = createSelector(
    getBckpState,
    (state: IBckpState) =>
        state[fromVboDashboardReducer.BckpVboDashboardFeatureKey]
);
export const getBckpVboDashboardStatusPerRange = createSelector(
    getBckpVboDashboardState,
    (state: fromVboDashboardReducer.IBckpVboDashboardState) =>
        state.statusPerRange
);

export const getBckpVboDashboardStatusPerRangeIsDataValid = createSelector(
    getBckpVboDashboardState,
    (state: fromVboDashboardReducer.IBckpVboDashboardState) =>
        state.statusPerRange.expirationDate !== null && DateTime.utc() < state.statusPerRange.expirationDate
);

export const getBckpVboDashboardLastSession = createSelector(
    getBckpVboDashboardState,
    (state: fromVboDashboardReducer.IBckpVboDashboardState) =>
        state.lastSession
);

export const getBckpVboDashboardLastSessionIsDataValid = createSelector(
    getBckpVboDashboardState,
    (state: fromVboDashboardReducer.IBckpVboDashboardState) =>
        state.lastSession.expirationDate !== null && DateTime.utc() < state.lastSession.expirationDate
);

export const getBckpVboDashboardCountServers = createSelector(
    getBckpVboDashboardState,
    (state: fromVboDashboardReducer.IBckpVboDashboardState) =>
        state.countServers
);

export const getBckpVboDashboardCountServersIsDataValid = createSelector(
    getBckpVboDashboardState,
    (state: fromVboDashboardReducer.IBckpVboDashboardState) =>
        state.countServers.validUntil !== null && DateTime.utc() < state.countServers.validUntil
);

export const getBckpVboDashboardTotalCapacity = createSelector(
    getBckpVboDashboardState,
    (state: fromVboDashboardReducer.IBckpVboDashboardState) =>
        state.totalCapacity
);

export const getBckpVboDashboardTotalCapacityIsDataValid = createSelector(
    getBckpVboDashboardState,
    (state: fromVboDashboardReducer.IBckpVboDashboardState) =>
        state.totalCapacity.validUntil !== null && DateTime.utc() < state.totalCapacity.validUntil
);

export const getBckpVboDashboardTicketing = createSelector(
    getBckpVboDashboardState,
    (state: fromVboDashboardReducer.IBckpVboDashboardState) =>
        state.ticketing
);

export const getBckpVboDashboardVariations = createSelector(
    getBckpVboDashboardState,
    (state: fromVboDashboardReducer.IBckpVboDashboardState) =>
        state.variations
);

export const getBckpVboDashboardVariationsIsDataValid = createSelector(
    getBckpVboDashboardState,
    (state: fromVboDashboardReducer.IBckpVboDashboardState) =>
        state.variations.validUntil !== null && DateTime.utc() < state.variations.validUntil
);

// #endregion

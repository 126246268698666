import { Action, combineReducers, createSelector } from '@ngrx/store';

import { DateTime } from 'luxon';

import { Request, RequestActionDetails } from '@apps/its2/domain/models';
import { Comparer, filterQuickSearch, UtilsService } from '@common/services/utils.service';
import { CustomFile, OperationStatus } from '@common/models/custom-file';
import { TreeviewService } from '@apps/its2/services/treeview.service';
import { TreeModel } from '@ui/components/form/controls/treeview';
import { CatalogEntry } from '@apps/its2/models';
import { Its2Constants } from '@apps/its2/its2.constants';
import { RequestsFilter } from '@apps/its2/services';

import * as fromRequestsReducer from './requests/requests.reducer';
import * as fromActionsReducer from './actions/actions.reducer';
import * as fromSelectedReducer from './selected/selected.reducer';
import * as fromDocumentsReducer from './documents/documents.reducer';
import * as fromValuesReducer from './values/values.reducer';
import * as fromMiscReducer from './misc/misc.reducer';
import * as fromCatalogsReducer from './catalogs/catalogs.reducer';
import { Its2DashboardFeatureKey } from './dashboard/dashboard.state';
import { Its2DashboardReducers } from './dashboard/dashboard.reducer';
import { getIts2State, IIts2State } from './its2.state';

export function Its2Reducers (state: IIts2State | undefined, action: Action)
{
    return combineReducers({
        [fromRequestsReducer.Its2RequestsFeatureKey]: fromRequestsReducer.Its2RequestsReducer,
        [fromActionsReducer.Its2ActionsFeatureKey]: fromActionsReducer.Its2ActionsReducer,
        [fromSelectedReducer.Its2SelectedFeatureKey]: fromSelectedReducer.Its2SelectedReducer,
        [fromDocumentsReducer.Its2DocumentsFeatureKey]: fromDocumentsReducer.Its2DocumentsReducer,
        [fromValuesReducer.Its2ValuesFeatureKey]: fromValuesReducer.Its2ValuesReducer,
        [fromMiscReducer.Its2MiscFeatureKey]: fromMiscReducer.Its2MiscReducer,
        [fromCatalogsReducer.Its2CatalogsFeatureKey]: fromCatalogsReducer.Its2CatalogsReducer,
        [Its2DashboardFeatureKey]: Its2DashboardReducers
    })(state, action);
}

// #region Requests
const getIts2RequestsState = createSelector(
    getIts2State,
    (state: IIts2State) =>
        state[fromRequestsReducer.Its2RequestsFeatureKey]
);

export const getIts2RequestsCollectionState = createSelector(
    getIts2RequestsState,
    (state: fromRequestsReducer.IIts2RequestsState) =>
        state.requests
);

export const getIts2RequestsIsDataValid = createSelector(
    getIts2RequestsState,
    (state: fromRequestsReducer.IIts2RequestsState) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);

export const getIts2SelectedRequest = createSelector(
    getIts2RequestsState,
    (state: fromRequestsReducer.IIts2RequestsState) =>
        state.selected
);

export const getIts2RequestsLoadingStatus = createSelector(
    getIts2RequestsState,
    (state) =>
        state.isLoading
);
export const getIts2RequestsLoadedStatus = createSelector(
    getIts2RequestsState,
    (state) =>
        state.isLoaded
);
export const getIts2RequestsOupsStatus = createSelector(
    getIts2RequestsState,
    (state) =>
        state.isOups
);

export const getIts2RequestsEmptyStatus = createSelector(
    getIts2RequestsState,
    (state) =>
        state.isEmpty
);

export const getIts2RequestsCounters = createSelector(
    getIts2RequestsState,
    (state) =>
        state.counters
);

export const {
    selectIds: getIdsRequests,
    selectEntities: getEntitiesRequests,
    selectAll: getAllRequests,
    selectTotal: getTotalRequests,
} = fromRequestsReducer.requestsAdapter.getSelectors(getIts2RequestsCollectionState);

export const getIts2RequestsNbWarning = createSelector(
    getAllRequests,
    (requests: Request[]) =>
        requests
            .filter((request: Request) =>
                request.waitingComplement || request.waitingValidation
            )
            .length
);

export const getIts2RequestsQuickSearched = createSelector(
    getIts2RequestsState,
    getAllRequests,
    (state: fromRequestsReducer.IIts2RequestsState, requests: Request[]): Request[] =>
        filterQuickSearch(requests, state.qsWord, Its2Constants.requestsQSFields)
            .filter((request: Request): boolean =>
                state.requestsFilter.onlySecuRequests
                    ? !UtilsService.isNullOrUndefined(request.useCaseTypeId)
                    : true
            )
);
export const getIts2RequestsPipelined = ( comparer: Comparer<Request>) => createSelector(
    getIts2RequestsQuickSearched,
    (requests: Request[], ): Request[] =>
        requests.sort(comparer)
);
// export const getIts2RequestsFilter = createSelector(
//     getIts2RequestsState,
//     (state: fromRequestsReducer.IIts2RequestsState) =>
//         state.requestsFilter
// );
export const getIts2RequestsMyTicketsState = createSelector(
    getIts2RequestsState,
    (state: fromRequestsReducer.IIts2RequestsState) =>
        RequestsFilter.convertFromPoto(state.requestsFilter).recipientIsMe
);

export const getDefaultFilterActive = createSelector(
    getIts2RequestsState,
    (state: fromRequestsReducer.IIts2RequestsState) =>
        state.defaultFilterActive
);

const getIts2MiscState = createSelector(
    getIts2State,
    (state: IIts2State) =>
        state[fromMiscReducer.Its2MiscFeatureKey]
);
// #endregion

// #region Catalogs
const getIts2CatalogsState = createSelector(
    getIts2State,
    (state: IIts2State) =>
        state[fromCatalogsReducer.Its2CatalogsFeatureKey]
);

export const getIts2CatalogsIncidentLoadingStatus = createSelector(
    getIts2CatalogsState,
    (state: fromCatalogsReducer.IIts2CatalogsState) =>
        state.incident.isLoading
);
export const getIts2CatalogsIncidentLoadedStatus = createSelector(
    getIts2CatalogsState,
    (state: fromCatalogsReducer.IIts2CatalogsState) =>
        state.incident.isLoaded
);
export const getIts2CatalogsIncidentOupsStatus = createSelector(
    getIts2CatalogsState,
    (state: fromCatalogsReducer.IIts2CatalogsState) =>
        state.incident.isOups
);
export const getIts2CatalogsIncidentEmptyStatus = createSelector(
    getIts2CatalogsState,
    (state: fromCatalogsReducer.IIts2CatalogsState) =>
        state.incident.isEmpty
);
export const getIts2CatalogsIncidentIsDataValid = createSelector(
    getIts2CatalogsState,
    (state) =>
        state.incident.expirationDate !== null && DateTime.utc() < state.incident.expirationDate
);
const getIts2CatalogsIncidentCollectionState = createSelector(
    getIts2CatalogsState,
    (state: fromCatalogsReducer.IIts2CatalogsState) =>
        state.incident.collection
);
const {
    selectAll: getAllCatalogsIncident
} = fromCatalogsReducer.catalogsAdapter.getSelectors(getIts2CatalogsIncidentCollectionState);
export const getIts2CatalogsIncident = createSelector(
    getIts2CatalogsState,
    getAllCatalogsIncident,
    (state: fromCatalogsReducer.IIts2CatalogsState, catalog: CatalogEntry[], lang: 'Fr' | 'En') =>
    {
        if (state.incident.isLoaded && !state.incident.isOups && !state.incident.isEmpty)
        {
            const stratifiedData = TreeviewService.flatToTree(catalog, lang).children;

            return new TreeModel(
                'id',
                'label',
                'children',
                stratifiedData,
                null,
                false,
                '',
                'its2-catalog-incident',
                'fa-plus-square',
                'fa-minus-square',
                'fa-greater-than'
            );
        }
        else
        {
            return null;
        }
    }
);

export const getIts2CatalogIncidentsGuidByAppli = createSelector(
    getIts2CatalogsState,
    getAllCatalogsIncident,
    (state: fromCatalogsReducer.IIts2CatalogsState, catalog: CatalogEntry[], appliGuid: string) =>
    {
        if (state.incident.isLoaded && !state.incident.isOups && !state.incident.isEmpty)
        {
            const xyz = catalog
                .filter((ce: CatalogEntry) =>
                    ce.myAntemetAGuid && ce.myAntemetAGuid.toUpperCase() === appliGuid.toUpperCase()
                );

            return xyz.length > 0 ? xyz[0] : null;
        }
        else
        {
            return null;
        }
    }
);

export const getIts2CatalogsServiceLoadingStatus = createSelector(
    getIts2CatalogsState,
    (state: fromCatalogsReducer.IIts2CatalogsState) =>
        state.service.isLoading
);
export const getIts2CatalogsServiceLoadedStatus = createSelector(
    getIts2CatalogsState,
    (state: fromCatalogsReducer.IIts2CatalogsState) =>
        state.service.isLoaded
);
export const getIts2CatalogsServiceOupsStatus = createSelector(
    getIts2CatalogsState,
    (state: fromCatalogsReducer.IIts2CatalogsState) =>
        state.service.isOups
);
export const getIts2CatalogsServiceEmptyStatus = createSelector(
    getIts2CatalogsState,
    (state: fromCatalogsReducer.IIts2CatalogsState) =>
        state.service.isEmpty
);
export const getIts2CatalogsServiceIsDataValid = createSelector(
    getIts2CatalogsState,
    (state: fromCatalogsReducer.IIts2CatalogsState) =>
        state.service.expirationDate !== null && DateTime.utc() < state.service.expirationDate
);
export const getIts2CatalogsServiceCollectionState = createSelector(
    getIts2CatalogsState,
    (state: fromCatalogsReducer.IIts2CatalogsState) =>
        state.service.collection
);
export const {
    selectAll: getAllCatalogsService
} = fromCatalogsReducer.catalogsAdapter.getSelectors(getIts2CatalogsServiceCollectionState);
export const getIts2CatalogsService = createSelector(
    getIts2CatalogsServiceLoadedStatus,
    getIts2CatalogsServiceOupsStatus,
    getIts2CatalogsServiceEmptyStatus,
    getAllCatalogsService,
    (isLoaded: boolean, isOups: boolean, isEmpty: boolean, catalog: CatalogEntry[], lang: 'Fr' | 'En') =>
    {
        if (isLoaded && !isOups && !isEmpty)
        {
            const stratifiedData = TreeviewService.flatToTree(catalog, lang).children;

            return new TreeModel(
                'id',
                'label',
                'children',
                stratifiedData,
                null,
                false,
                '',
                'its2-catalog-service',
                'fa-plus-square',
                'fa-minus-square',
                'fa-greater-than'
            );
        }
        else
        {
            return null;
        }
    }
);

export const getIts2CatalogServicesGuidByAppli = createSelector(
    getIts2CatalogsState,
    getAllCatalogsService,
    (state: fromCatalogsReducer.IIts2CatalogsState, catalog: CatalogEntry[], appliGuid: string) =>
    {
        if (state.incident.isLoaded && !state.incident.isOups && !state.incident.isEmpty)
        {
            const xyz = catalog
                .filter((ce: CatalogEntry) =>
                    ce.myAntemetAGuid && ce.myAntemetAGuid.toUpperCase() === appliGuid.toUpperCase()
                );

            return xyz.length > 0 ? xyz[0] : null;
        }
        else
        {
            return null;
        }
    }
);

export const getIts2CatalogsServiceQuestions = createSelector(
    getIts2CatalogsState,
    (state: fromCatalogsReducer.IIts2CatalogsState, params: {catalogGuid: string}) =>
        state.questions[params.catalogGuid]
);

export const getCreateService = createSelector(
    getIts2MiscState,
    (state: fromMiscReducer.IIts2MiscState) =>
        state.cs
);
// #endregion

/******* Files to upload *******/
export const getIts2RequestFileState = createSelector(
    getIts2MiscState,
    (state: fromMiscReducer.IIts2MiscState) =>
        state.files
);
export const {
    selectAll: getAllFiles,
    selectTotal: getTotalFiles,
} = fromMiscReducer.adapterFile.getSelectors(getIts2RequestFileState);
export const getNbFilesPerStatus = createSelector(
    getAllFiles,
    (files: CustomFile[]) => [
        files.length,
        files.filter((f) => f.uploadedStatus === OperationStatus.successful).length,
        files.filter((f) => f.uploadedStatus === OperationStatus.failed).length
    ]
);
/******* END *******/
// #endregion

// #region Selected => Favorites/Read
const getIts2SelectedState = createSelector(
    getIts2State,
    (state: IIts2State) =>
        state[fromSelectedReducer.Its2SelectedFeatureKey]
);
export const {
    selectAll: getAllSelected,
    selectTotal: getTotalSelected,
} = fromSelectedReducer.selectedAdapter.getSelectors(getIts2SelectedState);

export const countSelectedFavorite = createSelector(
    getAllSelected,
    (selected) =>
        selected.filter((sr) => sr.isFavorite).length
);
export const countSelectedNotFavorite = createSelector(
    getAllSelected,
    (selected) =>
        selected.filter((sr) => !sr.isFavorite).length
);
export const countSelectedRead = createSelector(
    getAllSelected,
    (selected) =>
        selected.filter((sr) => sr.isRead).length
);
export const countSelectedUnread = createSelector(
    getAllSelected,
    (selected) =>
        selected.filter((sr) => !sr.isRead).length
);
// #endregion

// #region Actions
const getIts2ActionsState = createSelector(
    getIts2State,
    (state: IIts2State) =>
        state[fromActionsReducer.Its2ActionsFeatureKey]
);

export const getIts2ActionsCollectionState = createSelector(
    getIts2ActionsState,
    (state: fromActionsReducer.IIts2ActionsState) =>
        state.detailedRequestActions
);

export const getIts2ActionsIsDataValid = createSelector(
    getIts2ActionsState,
    (state: fromActionsReducer.IIts2ActionsState) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);

export const getLastComment = createSelector(
    getIts2ActionsState,
    (state: fromActionsReducer.IIts2ActionsState) =>
        state.lastComment
);

export const getLastCommentIsLoadingStatus = createSelector(
    getIts2ActionsState,
    (state: fromActionsReducer.IIts2ActionsState) =>
        state.lastCommentIsLoading
);


export const {
    selectAll: getAllActions,
    selectTotal: getTotalActions,
} = fromActionsReducer.actionsAdapter.getSelectors(getIts2ActionsCollectionState);

export const getDetailedActionsExpandAllIsDisabled = createSelector(
    getAllActions,
    (detailedRequestActions: RequestActionDetails[]) =>
        detailedRequestActions.every((rad: RequestActionDetails) => rad.isExpanded === true)
);

export const getDetailedActionsCollapseAllIsDisabled = createSelector(
    getAllActions,
    (detailedRequestActions: RequestActionDetails[]) =>
        detailedRequestActions.every((rad: RequestActionDetails) => rad.isExpanded === false)
);
// #endregion

// #region Documents
const getIts2DocumentsState = createSelector(
    getIts2State,
    (state: IIts2State) =>
        state[fromDocumentsReducer.Its2DocumentsFeatureKey]
);

export const getIts2DocumentsCollectionState = createSelector(
    getIts2DocumentsState,
    (state: fromDocumentsReducer.IIts2DocumentsState) =>
        state.documents
);

export const getIts2DocumentsIsDataValid = createSelector(
    getIts2DocumentsState,
    (state: fromDocumentsReducer.IIts2DocumentsState) =>
        state.expirationDate !== null && DateTime.utc() < state.expirationDate
);

export const {
    selectAll: getAllDocuments,
    selectTotal: getTotalDocuments,
} = fromDocumentsReducer.documentsAdapter.getSelectors(getIts2DocumentsCollectionState);
// #endregion

// #region values
const getIts2ValuesState = createSelector(
    getIts2State,
    (state: IIts2State) =>
        state[fromValuesReducer.Its2ValuesFeatureKey]
);
export const getOptionsByPropertyName = createSelector(
    getIts2ValuesState,
    (state: fromValuesReducer.IIts2ValuesState, propertyName: string) =>
        state.values.entities[propertyName] ? state.values.entities[propertyName].options : []
);
//#endregion

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { BaseListDomainService } from '@common/domain/base-list-domain.service';
import { SearchResult, BackAuthEntitySearchResult } from '@common/models/search-result';
import { arrayMapper } from '@common/operators';

import { Entity } from './models/back/entity';
import { EntityApi } from '../api/models/back/entity-api';
import { ItsmEntitiesMiscDomainService } from '@apps/its2/domain/entities-misc-domain.service';
import { EntitiesListApiService } from '../api/entities-list-api.service';
import { BackConstants } from '@apps/back/back.constants';

@Injectable({
    providedIn: 'root'
})
export class EntitiesListService extends BaseListDomainService
{
    constructor (
        private entitiesListApiService: EntitiesListApiService,
        private departmentMiscDomainService: ItsmEntitiesMiscDomainService
    )
    {
        super();
    }

    getEntities (qs: string): Observable<Entity[]>
    {
        const entities: Observable<Entity[]> = this.entitiesListApiService
            .getEntities(qs)
            .pipe(
                map((obj: any): EntityApi[] =>
                    obj.value
                ),
                arrayMapper(Entity.mapperFromApi)
            );

        if (!qs.includes('external=true'))
        {
            return entities;
        }
        else
        {
            return entities
                .pipe(
                    withLatestFrom(this.departmentMiscDomainService.getEntities()),
                    map(([entities, departments]) =>
                    {
                        const existing = entities.map(e => e.name);
                        const toCreate: Entity[] = departments
                            .filter(d => !existing.includes(d.name))
                            .map(d =>
                                ({
                                    active:true,
                                    name: d.name,
                                    entityShortId: d.entityShortId,
                                    nbUsers: 0,
                                    isVirtual: true,
                                    external: true,
                                    statusKey: BackConstants.getStatusKey(true),
                                    statusClass: BackConstants.getCssStatus(true)
                                } as Entity)
                            );

                        return entities.concat(toCreate).sort((a, b) => a.name.localeCompare(b.name));
                    })
                );
        }
    }

    searchEntities (qs: string): Observable<SearchResult<Entity>[]>
    {
        return this.entitiesListApiService.searchEntities(qs)
            .pipe(
                map((obj: any): EntityApi[] =>
                    obj.value
                ),
                map((entities: EntityApi[]) =>
                    entities
                        .map(this.searchMapperFromApi, this)
                        .slice(0, 7)
                )
            );
    }

    private searchMapperFromApi (entityApi: EntityApi): BackAuthEntitySearchResult
    {
        return new BackAuthEntitySearchResult(Entity.mapperFromApi(entityApi));
    }
}


import { RangeKey } from '@common/enum';
import { RangeFilter } from '@common/filters/range-filter';

export class VeeamJobsFilter extends RangeFilter
{
    public jobType: string;

    constructor ()
    {
        super();

        this.initFilter();
    }

    public static mapperFromObject (source: any): VeeamJobsFilter
    {
        const target = new VeeamJobsFilter();

        Object.assign(target, source);

        return target;
    }

    public initFilter ()
    {
        const range = this.datesService.preRangeDates(RangeKey.CURRENT7DAYS);

        this.rangeSince = range.since;
        this.rangeUntil = range.until;
    }
}
